import React from "react";
import logo1 from "../../Data/Assets/Logos/Fantasy Park.png";
import logo2 from "../../Data/Assets/Logos/Joguiba.png";
import logo3 from "../../Data/Assets/Logos/Luzeco.png";
import logo4 from "../../Data/Assets/Logos/Natural Suit.png";
import logo5 from "../../Data/Assets/Logos/Valkanik.png";
import logo6 from "../../Data/Assets/Logos/sisma.png";
import { TopText } from "../../Components/TopText";
import { Stack } from "@mui/material";

export const LogosCarousel = () => {
  const logos = [logo1, logo2, logo3, logo4, logo5, logo6];

  const containerStyle = {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  };

  const trackStyle = {
    display: "flex",
    // la propiedad animation con @keyframes se define debajo con una etiqueta <style>
    animation: "scroll 15s linear infinite",
    width: "calc(200px * 12)",
  };

  const slideStyle = {
    width: "175px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  };

  const imgStyle = {
    width: "100%",
    height: "auto",
    filter: "grayscale(100%)",
  };

  return (
    <Stack spacing={4} mt="2rem">
      <TopText text="Ellos ya están un paso adelante" />
      <>
        {/* Aquí declaramos la animación con @keyframes */}
        <style>
          {`
          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}
        </style>

        <div style={containerStyle}>
          <div style={trackStyle}>
            {logos.map((logo, index) => (
              <div style={slideStyle} key={index}>
                <img src={logo} alt={`logo-${index}`} style={imgStyle} />
              </div>
            ))}
            {/* Se duplican los logos para crear efecto de carrusel continuo */}
            {logos.map((logo, index) => (
              <div style={slideStyle} key={`dup-${index}`}>
                <img src={logo} alt={`logo-dup-${index}`} style={imgStyle} />
              </div>
            ))}
            {logos.map((logo, index) => (
              <div style={slideStyle} key={`dup-${index}`}>
                <img src={logo} alt={`logo-dup-${index}`} style={imgStyle} />
              </div>
            ))}
            {logos.map((logo, index) => (
              <div style={slideStyle} key={`dup-${index}`}>
                <img src={logo} alt={`logo-dup-${index}`} style={imgStyle} />
              </div>
            ))}
            {logos.map((logo, index) => (
              <div style={slideStyle} key={`dup-${index}`}>
                <img src={logo} alt={`logo-dup-${index}`} style={imgStyle} />
              </div>
            ))}
            {logos.map((logo, index) => (
              <div style={slideStyle} key={`dup-${index}`}>
                <img src={logo} alt={`logo-dup-${index}`} style={imgStyle} />
              </div>
            ))}
          </div>
        </div>
      </>
    </Stack>
  );
};
