import { useTheme } from "@emotion/react";
import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";

export const Title = ({ title, variant = "h2", customColor = null, leftAlign = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  

  return variant === "h2" ? (
    <Typography
      lineHeight={"120%"}
      component="h2"
      sx={{
        fontSize: isMobile ? "1.5rem" : "2rem",
        fontWeight: "750",
        color: customColor? customColor: colors.grey[900],
        whiteSpace: "normal",
        overflow: "visible",
        fontFamily: "Inter",
        textAlign: leftAlign ? "start": "center",
      }}
    >
      {title}
    </Typography>
  ) : (
    <></>
  );
};
