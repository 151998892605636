import React from "react";
import { Box, Typography, Link, Grid, Stack } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { LiaArrowAltCircleDown, LiaArrowAltCircleRight } from "react-icons/lia";
import { FiArrowRight } from "react-icons/fi";
import { MdBuild } from "react-icons/md";
import { FaPlug, FaProjectDiagram } from "react-icons/fa";
import { AiOutlineEdit, AiOutlineLink } from "react-icons/ai";
import { LuPlug2 } from "react-icons/lu";

const cardData = [
  {
    title: "Fácil instalación",
    icon: <LuPlug2 />
    ,
    description:
      "Integra byNeural en cualquier web sin conocimientos técnicos. Todo listo en pocos clics para que tu cliente empiece a ver resultados desde el primer día.",
    linkText: "Más Info",
  },
  {
    title: "Compatible con tus sistemas actuales",
    icon: <FaProjectDiagram />,
    description:
      "Conecta byNeural con plataformas como HubSpot, WooCommerce, Make y más. Aumenta el alcance sin complicaciones técnicas.",
    linkText: "Más Info",
  },
  {
    title: "Configura asistentes a tu medida",
    icon: <AiOutlineEdit />,
    description:
      "Adapta el comportamiento y diseño de tus asistentes virtuales para alinearse con la identidad de marca y las necesidades de tus clientes.",
    linkText: "Más Info",
  },
];

export const Integrations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();
  return (
    <Box
      pt="1rem"
      sx={{
        maxWidth: pagewidth,
        margin: "0 auto",
        fontFamily: "'Inter', sans-serif", // Aplicando fuente Inter globalmente
      }}
    >
      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Stack
              sx={{
                justifyContent: "space-between",
                border: `2px solid ${colors.primary[500]}`,
                borderRadius: "1rem",
                padding: "2rem",
                textAlign: "left",
                height: "100%",
              }}
            >
              <Stack>
                <Box
                  sx={{
                    color: colors.primary[500],
                    fontSize: "32px",
                    marginBottom: "10px",
                  }}
                >
                  {card.icon}
                </Box>

                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "1.25rem", // 20px en rem
                    fontWeight: "bold",
                    marginBottom: "10px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem", // 16px en rem
                    color: colors.grey[600],
                    marginBottom: "10px",
                    fontFamily: "'Inter', sans-serif",
                  }}
                >
                  {card.description}
                </Typography>
              </Stack>
              <Link
                href="#"
                sx={{
                  color: colors.grey[700],
                  fontSize: "1rem", // 16px en rem
                  fontWeight: "500",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "10px",
                  fontFamily: "'Inter', sans-serif",
                  "&:hover": {
                    textDecoration: "underline",
                    color: colors.primary[500],
                  },
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <span>{card.linkText}</span>
                  <FiArrowRight size={18} />
                </Stack>
              </Link>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
