import React, { useState, useRef, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Stack,
  Box,
  Hidden,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import logo1 from "../Data/Assets/LogoNew7.png";
import logo2 from "../Data/Assets/sDark.png";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { tokens } from "../theme";
import { ButtonTopBar } from "./ButtonTopBar";
import SmallButton from "./SmallButton";

const CustomAppBar = styled(AppBar)(({ theme, isScrolled, isHidden }) => ({
  backgroundColor: !isScrolled ? "transparent" : "rgba(255, 255, 255, 0.8)",
  backdropFilter: isScrolled ? "blur(15px)" : "none",
  position: "fixed",
  margin: "auto",
  width: "100%",
  borderBottom: isScrolled ? "1px solid #EDEDED" : "none",
  transition:
    "background-color 0.3s ease, border-bottom 0.3s ease, top 0.3s ease",
  // Si isHidden es true, la barra se mueve hacia arriba (oculta) solo en pantallas grandes
  top: isHidden ? "-64px" : "0",
}));

const TopBar = ({ page, variant }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth, scrollToContact } = useScreenSize();
  const lastScrollY = useRef(0);

  // Detectar pantallas chicas y medianas (hasta md inclusive)
  const isSmallOrMd = useMediaQuery(theme.breakpoints.down("md"));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleRedirect = () => {
    window.location.href = "https://byneural.ai/";
  };

  const handleOpenDashboard = () => {
    window.open("https://www.byneural.ai/dashboard", "_blank");
  };

  // En pantallas grandes, la barra se oculta al hacer scroll; en pantallas <= md, se mantiene fija
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      setIsScrolled(currentScrollY > 0);

      if (!isSmallOrMd) {
        // Solo aplicamos el efecto de esconder la barra en pantallas grandes
        if (currentScrollY > lastScrollY.current) {
          setIsHidden(true);
        } else {
          setIsHidden(false);
        }
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSmallOrMd]);

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <CustomAppBar
        elevation={0}
        isScrolled={isScrolled}
        isHidden={isHidden}
        variant={variant}
        sx={{
          paddingRight: "0rem",
        }}
      >
        <Toolbar sx={{ color: "transparent" }}>
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: pagewidth,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* LOGO */}
            <button
              onClick={handleRedirect}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
            >
              <img
                src={logo1}
                style={{
                  width: isSmallOrMd ? "8rem" : "8.5rem",
                  marginTop: isSmallOrMd ? "0.5rem" : "0.25rem",
                  marginLeft: isSmallOrMd ? "0.625rem" : "0rem",
                }}
                alt="Logo"
              />
            </button>

            {/* Botones en pantallas grandes */}
            {!isSmallOrMd && (
              <Stack direction="row" spacing={1.5}>
                <Stack direction="row" spacing={0}>
                  {/* <SmallButton
                    label="Nosotros"
                    variant={"transparent"}
                    onClick={() => navigate("/nosotros")}
                  />
                  <SmallButton
                    label="Soluciones"
                    variant={"transparent"}
                    onClick={() => navigate("/soluciones")}
                  /> */}
                  <SmallButton
                    label="Partners"
                    variant={"transparent"}
                    // onClick={() => navigate("/partners")}
                    onClick={scrollToContact}
                  />
                </Stack>

                <SmallButton
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <FaRegUser size={16} />
                      <span>{"Acceder"}</span>
                    </Stack>
                  }
                  onClick={handleOpenDashboard}
                  variant={"secondary"}
                />
                <SmallButton
                  label="Solicita una Demo"
                  variant={"primary"}
                  onClick={scrollToContact}
                />
              </Stack>
            )}

            {/* Ícono Hamburguesa en pantallas chicas y medianas */}
            {isSmallOrMd && (
              <IconButton
                onClick={() => setIsDrawerOpen(true)}
                sx={{
                  color: "#000000",
                }}
              >
                <FiMenu size={24} />
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </CustomAppBar>

      {/* Espaciador para que el contenido no quede debajo del AppBar */}
      <Toolbar />

      {/* Menú lateral personalizado */}
      <Box
        onClick={() => setIsDrawerOpen(false)}
        // Fondo semi-transparente para que al abrir el menú se oscurezca el fondo
        style={{
          display: isDrawerOpen ? "block" : "none",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          zIndex: 1200,
        }}
      ></Box>
      <Box
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "75%",
          maxWidth: "280px",
          height: "100vh",
          backgroundColor: "#FFFFFF",
          transform: isDrawerOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1300,
          fontFamily: "Inter, sans-serif", // Fuente Inter
          boxShadow: "-5px 0 10px rgba(0,0,0,0.1)",
          padding: "1.5rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {/* Al hacer clic en cualquiera de los botones también se debe cerrar el menú */}
        <Stack spacing={3} alignItems="start">
          {/* <SmallButton
            label="Nosotros"
            variant={"transparent"}
            onClick={() => {
              setIsDrawerOpen(false);
              navigate("/nosotros");
            }}
          />
          <SmallButton
            label="Soluciones"
            variant={"transparent"}
            onClick={() => {
              setIsDrawerOpen(false);
              navigate("/soluciones");
            }}
          /> */}
          <SmallButton
            label="Partners"
            variant={"transparent"}
            onClick={() => {
              setIsDrawerOpen(false);
              scrollToContact();
              // navigate("/partners");
            }}
          />

          <SmallButton
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <FaRegUser size={16} />
                <span>{"Acceder"}</span>
              </Stack>
            }
            onClick={() => {
              setIsDrawerOpen(false);
              handleOpenDashboard();
            }}
            variant={"secondary"}
          />
          <SmallButton
            label="Solicita una Demo"
            variant={"primary"}
            onClick={() => {
              setIsDrawerOpen(false);
              scrollToContact();
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default TopBar;
