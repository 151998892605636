import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Grid, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import mainImage from "../Data/Assets/Main.png";
import CustomBtn from "./CustomBtn";
import { PiCheckBold } from "react-icons/pi";
import SmallButton from "./SmallButton";
import { TopText } from "./TopText";

const TitleBox = ({ variant }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animation, setAnimation] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, pagewidth, scrollToContact } = useScreenSize();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex", // Habilita flexbox
        flexDirection: "column", // Organiza los hijos en columna
        justifyContent: "center", // Centra los hijos verticalmente
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: `${pagewidth + 200}px`,
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              marginTop:
                screenSize !== "xs" && screenSize !== "sm" ? "6rem" : "4rem",
              // p: { xs: "0rem", sm: "0 3rem", md: "0 8rem", lg: "0 15rem" },
              display: "flex", // Usamos flexbox para alinear los botones en línea
              flexDirection: "column", // Organiza los hijos en columna
              justifyContent:
                screenSize !== "xs" && screenSize !== "sm" ? "left" : "center", // Alinea los botones al inicio del contenedor
            }}
          >
            {screenSize !== "xs" && screenSize !== "sm" && (
              <TopText text="Aprovecha el potencial de la IA"/>
            )}
            <Typography
              lineHeight={"120%"}
              component="h1"
              sx={{
                fontSize: { xs: "2rem", md: "4rem" },
                fontWeight: { xs: "650", md: "750" } ,
                color: colors.grey[900],
                whiteSpace: "normal",
                overflow: "visible",
                fontFamily: "Inter",
                textAlign:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "center"
                    : "center",
              }}
            >
              Impulsa tu{" "}
              <span style={{ color: colors.primary[500] }}>Agencia </span>
              creando Asistentes Virtuales con{" "}
              <span style={{ color: colors.primary[500] }}>IA </span>para tus
              clientes
            </Typography>
            <Typography
              fontSize={
                screenSize !== "xs" && screenSize !== "sm" ? "1.75rem" : "1rem"
              }
              lineHeight={"1.35"}
              component="h2"
              sx={{
                p: { xs: "0rem", sm: "0 3rem", md: "0 8rem", lg: "0 12rem" },

                marginTop: { xs: "2rem", md: "3rem" },
                color: colors.grey[600],
                textAlign:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "center"
                    : "center",
                fontFamily: "Inter",
                fontWeight: "400",
              }}
            >
              Combina Inteligencia Artificial y análisis de conversaciones para
              crear estrategias personalizadas que aumenten ventas y fidelidad.
            </Typography>
            <Box
              sx={{
                marginTop: { xs: "2rem", md: "3rem" }, // Espacio entre el texto y los botones
                width: "100%",
                display: "flex",
                justifyContent:
                  screenSize !== "xs" && screenSize !== "sm"
                    ? "left"
                    : "center",
              }}
            >
              <Box
                sx={{
                  width:
                    screenSize !== "xs" && screenSize !== "sm"
                      ? "100%"
                      : "100%",
                  display: "flex", // Usamos flexbox para alinear los botones en línea
                  flexDirection: "row",
                  gap: "1rem", // Espacio entre los botones
                  justifyContent: "center", // Centrar los botones en todos los tamaños de pantalla
                }}
              >
                <SmallButton label="Contáctanos" variant="secondary" onClick={scrollToContact}/>
                <Stack>
                  <SmallButton
                    label="Solicitar Demo Gratis"
                    variant="primary"
                    onClick={scrollToContact}
                  />
                  {/* <Typography
                    sx={{
                      width: "100%",
                      // pl: { xs: "0", md: "1rem" },
                      display: "flex", // Usa flexbox para poder alinear los ítems internos
                      alignItems: "center", // Alinea los ítems verticalmente en el centro
                      color: colors.grey[600],
                      fontFamily: "Inter",
                      fontSize: { xs: "0.75rem", md: "0.875rem" },
                      textAlign: "center",
                    }}
                  >
                    <PiCheckBold style={{ marginRight: "0.5rem" }} />
                    Sin tarjeta
                  </Typography> */}
                </Stack>
                {/* <CustomBtn
                  variant="primary"
                  text={
                    screenSize !== "xs" && screenSize !== "sm"
                      ? "Solicita una Demo Gratis"
                      : "Solicitar Demo"
                  }
                  version={variant}
                />
                <CustomBtn
                  variant="sec"
                  text={
                    screenSize !== "xs" && screenSize !== "sm"
                      ? "Contacta con Nosotros"
                      : "Contáctanos"
                  }
                  version={variant}
                /> */}
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* } */}
      </Grid>
    </Box>
  );
};

export default TitleBox;
