import React from "react";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { Typography, Box, CircularProgress, lighten } from "@mui/material";

const SmallButton = ({
  label,
  onClick = () => {},
  variant = "primary",
  borderRadius = "0.5rem",
  height = "2rem",
  customWidth = "fit-content",
  loading = false,
  disabled = false,
}) => {
  const { scrollToContact } = useScreenSize();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const baseStyles = {
    position: "relative", // Para poder posicionar el loader de forma absoluta
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: customWidth,
    padding: variant === "transparent" ? "0 0.625rem" : "0 1rem",
    borderRadius,
    fontWeight: 600,
    fontFamily: "Inter, sans-serif",
    fontSize: "0.875rem",
    height,
    textAlign: "center",
    textTransform: "none",
    transition: "background-color 0.3s ease",
    border: "1px solid",
    cursor: disabled || loading ? "not-allowed" : "pointer",
    color:
      variant === "primary"
        ? colors.grey[100]
        : variant === "secondary" || variant === "transparent" || variant==="white"
        ? colors.grey[700]
        : colors.redAccent[500],
    backgroundColor:
      variant === "primary"
        ? colors.primary[500]
        : variant === "secondary" || variant === "transparent" 
        ? "transparent" :
        variant==="white" ? colors.grey[100]
        : colors.grey[100],
    borderColor:
      variant === "primary"
        ? colors.primary[500]
        : variant === "secondary"
        ? colors.grey[700]
        : variant === "transparent"
        ? "transparent" : variant==="white" ? colors.grey[100]
        : colors.redAccent[500],
    overflow: "hidden", // Opcional, por si quieres recortes si algo se sale
  };

  // Ajustamos estilos si el botón está deshabilitado o en modo loading
  if (disabled) {
    baseStyles.backgroundColor =
      variant === "primary"
        ? lighten(colors.primary[500], 0.4)
        : colors.grey[100];
    baseStyles.color =
      variant === "primary" ? colors.grey[100] : colors.grey[500];
    baseStyles.borderColor =
      variant === "primary" ? colors.grey[100] : colors.grey[500];
  }

  const handleMouseEnter = (e) => {
    // Solo si no está deshabilitado ni en modo loading se aplica el hover
    if (!disabled && !loading) {
      e.currentTarget.style.backgroundColor =
        variant === "primary"
          ? lighten(colors.primary[500], 0.2)
          : variant === "secondary"|| variant==="white"
          ? lighten(colors.grey[700], 0.9)
          : variant === "transparent" 
          ? colors.grey[100]
          : lighten(colors.redAccent[500], 0.9);
      e.currentTarget.style.textDecoration =
        variant === "transparent" ? "underline" : "none";
    }
  };

  const handleMouseLeave = (e) => {
    // Solo si no está deshabilitado ni en modo loading se quita el hover
    if (!disabled && !loading) {
      e.currentTarget.style.backgroundColor =
        variant === "primary"
          ? colors.primary[500]
          : variant === "secondary"
          ? colors.grey[100]
          : colors.grey[100];
      e.currentTarget.style.textDecoration = "none";
    }
  };

  const handleClick = (e) => {
    if (!disabled && !loading) {
      onClick?.(e);
    }
  };

  return (
    <button
      style={baseStyles}
      disabled={disabled || loading}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {/* Span que reserva el espacio del label (aunque no se vea si está en loading) */}
      <span
        style={{
          visibility: loading ? "hidden" : "visible",
          whiteSpace: "nowrap", // Para no cortar el texto
        }}
      >
        {label}
      </span>

      {loading && (
        /* Loader centrado de forma absoluta */
        <span
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size="1.25rem"
            sx={{
              color:
                variant === "primary"
                  ? colors.grey[100]
                  : variant === "secondary"
                  ? colors.grey[700]
                  : colors.grey[500],
            }}
          />
        </span>
      )}
    </button>
  );
};

export default SmallButton;
