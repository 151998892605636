import React from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import TopBar from "../../Components/TopBar";
import TitleBox from "../../Components/TitleBox";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { ServicesOfferedBox } from "../../Components/ServicesOfferedBox";
import { ContactFormL } from "../../Components/ContactFormL";
import Footer from "../../Components/Footer";
import { AccordionComponent } from "../../Components/AccordionComponent";
import { AccordionComponentMobile } from "../../Components/AccordionComponentMobile";
import SecondTitleBox from "../../Components/SecondTitleBox";
import { UseCasesBox } from "../../Components/UseCasesBox";

import CustomDivider from "../../Components/CustomDivider";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

import background from "../../Data/Assets/Images/Fondo.svg";

import BenefitsCarrousel from "../../Components/BenefitsCarrousel";
import { VideoComponent } from "../../Components/VideoComponent";
import { LogosCarousel, LogosCarusel } from "./LogosCarusel";
import { TitleBox2 } from "./TitleBox2";
import { Integrations } from "./Integrations";
import { PlatformTabs } from "./PlatformTabs";
import { PartnersProgram } from "./PartnersProgram";

export const MainPage = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { screenSize, contactFormRef } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const backgroundColor = "transparent";

  const determinePadding = () => {
    switch (screenSize) {
      case "xs":
        return "0rem 0.75rem";
      case "sm":
        return "0rem 1.5rem";
      case "md":
        return "0rem 2rem";
      case "lg":
        return "0rem 2rem";
      default:
        return "0rem 1rem";
    }
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.transform = "scale(1.1)";
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.transform = "scale(1)";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        overflowX: "hidden",
      }}
    >
      {/* Background */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: -10,
          width: "100vw",
          zIndex: -1,
        }}
      >
        <>
          <div
            style={{
              width: "100vw",
              height: "160rem",
              backgroundImage: isMobile
                ? `url(${background})`
                : `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </>
      </div>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "0rem", width: "100%" }}
      >
        {/* Hero */}
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            padding: determinePadding(),
            overflow: "hidden",
            marginTop: "0rem",
            // backgroundColor:"red"
          }}
        >
          <div style={{ backgroundColor: backgroundColor }}>
            <TitleBox />
          </div>
        </Grid>

        {/* Image Carrousel */}
        <Grid
          item
          alignItems={"center"}
          xs={12}
          mt={{ xs: "2rem", lg: "4rem" }}
          sx={{ padding: determinePadding() }}
        >
          <div style={{ backgroundColor: backgroundColor }}>
            <VideoComponent />
          </div>
        </Grid>

        <Grid
          item
          alignItems={"center"}
          xs={12}
          mt={{ xs: "1rem", lg: "2rem" }}
          sx={{ padding: determinePadding() }}
        >
          <div style={{ backgroundColor: backgroundColor }}>
            <LogosCarousel />
          </div>
        </Grid>

        <Grid
          item
          alignItems={"center"}
          xs={12}
          mt={{ xs: "1rem", lg: "4rem" }}
          sx={{ padding: determinePadding(), pb: { xs: "2rem", md: "0rem" } }}
          backgroundColor="#F5F5F9"
        >
          <Stack sx={{ backgroundColor: backgroundColor }}>
            <TitleBox2
              toptext={"Crea Asistentes Virtuales con byneural"}
              title={
                <>
                  {" "}
                  Los chatbots son el pasado,{" "}
                  <span style={{ color: colors.primary[500] }}>
                    byNeural{" "}
                  </span>{" "}
                  es el{" "}
                  <span style={{ color: colors.primary[500] }}>futuro</span>{" "}
                </>
              }
              subtitle="Olvídate de las conversaciones dirigidas. Con byNeural, tu agencia puede crear asistentes virtuales que no solo conversan, sino que entienden, analizan y generan valor para tus clientes."
            />
            <AccordionComponent />
          </Stack>
        </Grid>
        {/* Accordion */}

        {/* Use Cases */}
        {/* <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            width: "100%",
            padding: determinePadding(),
            mt: { xs: "0rem", lg: "1rem" },
          }}
        >
          <UseCasesBox />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: "5rem", md: "7rem" },
            width: "100%",
            padding: determinePadding(),
          }}
        >
          <CustomDivider />
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: "1rem", md: "1.5rem" },
            mb: { xs: "2rem", md: "0rem" },
            padding: determinePadding(),

            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack sx={{ backgroundColor: backgroundColor }}>
            <TitleBox2
              toptext={"Funcionalidades e integraciones"}
              title={
                <>
                  {" "}
                  Integración con{" "}
                  <span style={{ color: colors.primary[500] }}>
                    {" "}
                    cualquier web{" "}
                  </span>{" "}
                  o proyecto{" "}
                </>
              }
              subtitle="Se integra en menos de 5 minutos con cualquier web, con cualquier sistema, con cualquier plataforma, con cualquier negocio."
            />
            <Integrations />
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: "1rem", md: "6rem" },
            display: "flex",
            justifyContent: "center",
            backgroundColor: colors.grey[900],
            padding: determinePadding(),
          }}
        >
          <Stack sx={{ backgroundColor: backgroundColor }}>
            <PlatformTabs />
          </Stack>
        </Grid>

        {!isMobile && (
          <Grid
            item
            xs={12}
            sx={{
              mt: { xs: "1rem", md: "3rem" },
              display: "flex",
              justifyContent: "center",
              padding: determinePadding(),
            }}
          >
            <Stack sx={{ backgroundColor: backgroundColor }}>
              <TitleBox2
                toptext={"Todo lo que necesita tu agencia"}
                title={
                  <>
                    {" "}
                    Más que una plataforma, una{" "}
                    <span style={{ color: colors.primary[500] }}>
                      solución integral{" "}
                    </span>
                  </>
                }
                subtitle="Con tecnología avanzada y funcionalidades únicas, byNeural lleva tu agencia al siguiente nivel."
              />
              <BenefitsCarrousel />
            </Stack>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: "1rem", md: "5rem" },
            display: "flex",
            justifyContent: "center",
            padding: determinePadding(),
          }}
        >
          <Box
            style={{
              width: "100%",
              backgroundColor: backgroundColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PartnersProgram />
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sx={{ mt: "2rem", padding: determinePadding() }}
          ref={contactFormRef}
        >
          <CustomDivider />
        </Grid> */}
        <Grid
          item
          align={"center"}
          xs={12}
          sx={{
            width: "100%",
            marginTop: { xs: "4rem", md: "6rem" },
            pb:"7rem",
            pt:"4rem",
            backgroundColor: "#F5F5F9",
          }}
        >
          <ContactFormL />
        </Grid>
        <Grid item xs={12} >
          <Footer />
        </Grid>
      </Grid>
    </Box>
  );
};
