import { useTheme } from "@mui/material/styles";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

export const TopText = ({ text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Detectar si la pantalla es xs, sm o md
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box p="1rem 0">
      <Typography
        lineHeight={"100%"}
        component="h3"
        sx={{
          fontSize: isMobile ? "0.75rem" : "0.875rem", // Reducir el tamaño en móviles
          fontWeight: "400",
          color: colors.primary[500],
          whiteSpace: "normal",
          overflow: "visible",
          fontFamily: "Inter",
          textAlign: "center",
          textTransform: "uppercase",
          letterSpacing: "2px",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
