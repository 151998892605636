import React, { useRef, useState } from "react";
import { Box, IconButton, Stack, useMediaQuery } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import video from "../Data/Assets/Video2.mp4";
import { useScreenSize } from "../hooks/ScreenSizeContext";
import { FaPlay } from "react-icons/fa";

export const VideoComponent = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detecta si es xs, sm o md

  const { pagewidth } = useScreenSize();

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
      if (isMobile && videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen(); // Activa pantalla completa en móviles
      }
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: `${pagewidth + 200}px` ,
        aspectRatio: "16 / 9",
        margin: "0 auto",
        borderRadius: "20px",
        overflow: "hidden",
        boxShadow: 3,
      }}
    >
      {/* Video */}
      <video
        ref={videoRef}
        autoPlay={!isMobile} // Solo autoplay en pantallas grandes
        loop
        muted
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src={video} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>

      {/* Capa de Blur y Play (Solo si el video no está reproduciéndose en pantallas móviles) */}
      {isMobile && !isPlaying && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Oscurece el video
            backdropFilter: "blur(8px)", // Aplica blur
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handlePlayVideo}
        >
          <Stack>
            <IconButton sx={{ color: "white", fontSize: "2.5rem" }}>
              <FaPlay sx={{ fontSize: 40 }} />
            </IconButton>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
