import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import { tokens } from "../theme";
import { Stack } from "@mui/system";

export const PrimaryText = ({
  text,
  italic = false,
  bold = false,
  greyColor = false,
  isComment = false,
  primaryColor = false,
  center = false,
  customColor = null,
  fontSize = null,
  lineClamp = 5, // Para manejar múltiples líneas si es necesario
  isLink = false, // Indica si el texto es un enlace interactivo
  goToDomain = null, // Nueva propiedad para redirigir a un dominio
}) => {
  const theme = useTheme();
  const colors = tokens(theme);

  // Manejar clic para redirigir
  const handleClick = () => {
    if (goToDomain) {
      window.open(goToDomain, "_blank", "noopener,noreferrer");
    }
  };
  

  return (
    
      <Typography
        onClick={handleClick} // Llama a la función de redirección si goToDomain está presente
        sx={{
          fontSize: fontSize ? fontSize : isComment ? "0.75rem" : "0.875rem",
          fontFamily: "Inter",
          fontStyle: isComment ? (italic ? "italic" : null) : (italic ? "italic" : null),
          fontWeight: bold ? 600 : 400,
          color: customColor
            ? customColor
            : greyColor || isComment
            ? colors.grey[500]
            : primaryColor
            ? colors.primary[500]
            : colors.grey[600],
          marginBottom: isComment ? "0.4rem" : "0rem",
          textAlign: center ? "center" : "left",
          overflow: "hidden",
          textOverflow: "ellipsis", // Añade "..." al final si se corta
          whiteSpace: lineClamp ? "normal" : "nowrap", // Permite o no saltos de línea
          display: lineClamp ? "-webkit-box" : "block", // Flexbox para múltiples líneas
          WebkitLineClamp: lineClamp ? lineClamp : null, // Limita el texto a X líneas
          WebkitBoxOrient: lineClamp ? "vertical" : null, // Necesario para WebkitLineClamp
          wordBreak: "break-word", // Rompe palabras largas para que no excedan el contenedor
          overflowWrap: "break-word", // Asegura que las palabras largas también se corten
          textDecoration: isLink || goToDomain ? "none" : "inherit", // Sin subrayado inicialmente si es un enlace
          cursor: isLink || goToDomain ? "pointer" : "default", // Cambia el cursor si es un enlace o hay un dominio
          "&:hover": (isLink || goToDomain) && {
            color: colors.primary[500], // Azul al hacer hover
            textDecoration: "underline", // Subrayado al hacer hover
          },
        }}
      >
        {text}
      </Typography>
 
  );
};
