import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import laptopsImage from "../../Data/Assets/laptops.png";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { Title } from "./Title";
import { PrimaryText } from "../../Components/PrimaryText";
import SmallButton from "../../Components/SmallButton";
import { BsArchive } from "react-icons/bs";

export const PartnersProgram = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth, scrollToContact } = useScreenSize();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      pt="1rem"
      sx={{
        width: "100%",
        maxWidth: pagewidth,
        fontFamily: "'Inter', sans-serif", // Aplicando fuente Inter globalmente
      }}
    >
      <Grid container spacing={0}>
        {!isMobile && (
          <Grid
            item
            xs={8}
            md={7}
            lg={8}
            sx={{
              pr: "1rem",
              height: "25.5rem",
            }}
          >
            <Box
              sx={{
                pr: "1rem",
                backgroundImage: `url(${laptopsImage})`,
                backgroundSize: "cover", // Ajusta la imagen para cubrir todo el box
                backgroundPosition: "center", // Centra la imagen
                backgroundRepeat: "no-repeat",
                height: "25.5rem",
                borderRadius: "1rem",
                width: "100%",
              }}
            ></Box>
          </Grid>
        )}
        <Grid
          item
          md={5}
          lg={4}
          xs={12}
          sx={{
            height: isMobile ? "22rem" : "25.5rem",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              background: `linear-gradient(to right, ${colors.primary[500]}, #866AE7)`, // Azul oscuro a azul claro
              height: isMobile ? "22rem" : "25.5rem",
              borderRadius: "1rem",
              width: "100%",
              p: "3.5rem",
            }}
          >
            {/* Icono */}
            <Box>
              <BsArchive
                style={{ fontSize: "3rem", color: colors.primary[100] }}
              />
            </Box>

            {/* Texto principal */}
            <Box>
              <Title
                title={"Programa de partners"}
                customColor={colors.grey[100]}
                leftAlign
              />
              <Box mt="1rem">
                <PrimaryText
                  text="Una plataforma diseñada para ti."
                  customColor={"white"}
                />
              </Box>
            </Box>

            {/* Botón */}
            <Box pt="2rem">
              <SmallButton
                // onClick={() => {}}
                label="Solicitar información"
                variant="white"
                onClick={scrollToContact}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
