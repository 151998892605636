import * as React from "react";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { useScreenSize } from "../hooks/ScreenSizeContext";

import conv from "../Data/Assets/Images/Acordion/Conversación.png";
import stats from "../Data/Assets/Images/Acordion/Estadísticas.png";
import recommend from "../Data/Assets/Images/Acordion/Recomendaciones.png";

import { PiUsersBold } from "react-icons/pi";
import { AiOutlineBulb, AiOutlineShopping } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdOutlineLightbulb, MdTrendingUp } from "react-icons/md";

// Contenedor padre de cada "acordeón"
const CustomAccordion = styled("div")(() => ({
  marginTop: "1rem",
  marginBottom: "1rem",
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "none",
  borderRadius: "1rem",
  "&::before": {
    display: "none",
  },
}));

// Encabezado del acordeón
const CustomAccordionSummary = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  cursor: "pointer",
  padding: "1.5rem 1.5rem 1rem 1.5rem",
  // Añade aquí otros estilos que desees para el 'summary'
}));

export const AccordionComponent = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedImage, setSelectedImage] = React.useState(conv);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

  const isExpanded = (panel) => expanded === panel;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (panel, image) => () => {
    if (expanded === panel) {
      setExpanded(false);
      setSelectedImage(conv);
    } else {
      setExpanded(panel);
      setSelectedImage(image);
    }
  };

  // Datos de cada panel
  const panels = [
    {
      key: "panel1",
      icon: <PiUsersBold style={{ fontSize: "1.5rem" }} />,
      number: "01.",
      title: "Descubre información de los clientes",
      content:
        "Cada interacción con los clientes se convierte en una oportunidad para obtener insights que otras plataformas no pueden ofrecer. Analiza patrones de conversación, detecta necesidades ocultas y personaliza tus estrategias con información que solo byNeural puede capturar. Lleva tus campañas más allá de los datos convencionales y crea un impacto real.",
      videoOrImage: conv,
    },
    {
      key: "panel2",
      number: "02.",
      icon: <MdTrendingUp style={{ fontSize: "1.5rem" }} />,
      title: "Recomendaciones de mejora",
      content:
        "byNeural no solo analiza, sino que identifica áreas de mejora específicas basándose en datos reales de conversaciones. Detecta tendencias, anticipa necesidades y sugiere cambios estratégicos que marcan la diferencia. Ninguna otra plataforma te ofrece este nivel de orientación para optimizar tus resultados.",
      videoOrImage: recommend,
    },
    {
      key: "panel3",
      number: "03.",
      icon: <AiOutlineShopping style={{ fontSize: "1.5rem" }} />,
      title: "Aumenta la conversión",
      content:
        "byNeural no solo conversa, actúa. Recomienda productos personalizados a los usuarios, muestra vídeos informativos, envía formularios adaptados, etc. Todo esto con la inteligencia de la IA que entiende las necesidades de cada cliente y optimiza su experiencia en tiempo real.",
      videoOrImage: stats,
    },
  ];

  return (
    <Box
      sx={{
        mt: "2rem",
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ maxWidth: `${pagewidth}px`, minHeight: "38rem" }}>
        <Grid item xs={12} md={6} pr={isMobile ? "0rem" : "1rem"}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ marginLeft: "0rem" }}>
                {panels.map((panel) => {
                  const expandedPanel = isMobile ? true : isExpanded(panel.key);

                  return (
                    <CustomAccordion
                      key={panel.key}
                      style={{
                        // Cambia color/borde según estado:
                        backgroundColor: expandedPanel
                          ? colors.grey[100]
                          : "transparent",
                        border: expandedPanel
                          ? `1px solid ${colors.grey[300]}`
                          : "none",
                        borderRadius: "1rem",
                        // Transiciones suaves para borde y color
                        transition: "all 0.4s ease",
                      }}
                    >
                      {/* Encabezado del panel */}
                      <CustomAccordionSummary
                        onClick={handleChange(panel.key, panel.videoOrImage)}
                        onMouseEnter={handleChange(
                          panel.key,
                          panel.videoOrImage
                        )}
                        style={{
                          minHeight: "3.5rem", // altura mínima al estar plegado
                          display: "flex",
                          alignItems: "center",
                          // padding: expandedPanel
                          //   ? "1.75rem 1.75rem 0rem 1.75rem"
                          //   : "1.75rem 1.75rem 1rem 1.75rem",
                          // Transición del color en el texto
                          transition: "background-color 0.4s ease",
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ width: "100%" }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontWeight: 500,
                              color: expandedPanel
                                ? colors.primary[500]
                                : colors.grey[300],
                              transition: "color 0.4s ease",
                            }}
                          >
                            {panel.icon}
                          </Typography>
                          <Stack
                            direction="column"
                            spacing={1}
                            sx={{ width: "100%" }}
                          >
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                fontSize: "1.125rem",
                                color: expandedPanel
                                  ? colors.grey[900]
                                  : colors.grey[500],
                                transition: "color 0.4s ease",
                              }}
                            >
                              {panel.title}
                            </Typography>
                          </Stack>
                        </Stack>
                      </CustomAccordionSummary>

                      {/* Contenido que se pliega/despliega con animación de altura */}
                      <Collapse
                        in={expandedPanel}
                        timeout={500} // Duración de la transición (0.5s)
                        unmountOnExit
                      >
                        <Box
                          sx={{
                            pl: "4.125rem",
                            pr: "1.5rem",
                            pb: "1.5rem",
                            mt: "-0.75rem",
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "1rem",
                              color: colors.grey[500],
                              pt: 1,
                            }}
                          >
                            {panel.content}
                          </Typography>
                        </Box>
                      </Collapse>
                    </CustomAccordion>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Imagen/Video dinámico */}
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderRadius: "1.5rem",
                width: "35rem",
                height: "35rem",

                backgroundColor: colors.grey[100],
                // backgroundColor: "green",
                ml: "3rem",
              }}
            >
              {selectedImage.includes(".mp4") ? (
                <video
                  src={selectedImage}
                  style={{
                    width: "100%",
                    height: "25rem",
                    borderRadius: "1.5rem",
                  }}
                  autoPlay
                  muted
                  loop
                />
              ) : (
                <img
                  src={selectedImage}
                  style={{
                    width: "100%",
                    height: "35rem",
                    borderRadius: "1rem",
                  }}
                  alt="dynamic content"
                />
              )}
            </Box>

            {/* Pre-carga de archivos multimedia de forma invisible */}
            <div style={{ display: "none" }}>
              <img src={conv} alt="precarga script" />
              <img src={recommend} alt="precarga script" />
              <img src={stats} alt="precarga script" />
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
