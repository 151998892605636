import { useTheme } from "@emotion/react";
import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { Title } from "./Title";
import { PrimaryText } from "../../Components/PrimaryText";

import AparienciaImageXS from "../../Data/Assets/Images/PlataformaXS/Apariencia.png";
import BaseConocimientoImageXS from "../../Data/Assets/Images/PlataformaXS/Base conocimiento.png";
import EstadisticasImageXS from "../../Data/Assets/Images/PlataformaXS/Estadísticas.png";
import RecomendacionesImageXS from "../../Data/Assets/Images/PlataformaXS/Recomendaciones.png";
import WorkflowsImageXS from "../../Data/Assets/Images/PlataformaXS/Workflows.png";

import AparienciaImage from "../../Data/Assets/Images/PlataformaXL/Apariencia.png";
import BaseConocimientoImage from "../../Data/Assets/Images/PlataformaXL/Base Conocimiento.png";
import EstadisticasImage from "../../Data/Assets/Images/PlataformaXL/Estadísticas.png";
import InformesImage from "../../Data/Assets/Images/PlataformaXL/Informes.png";
import WorkflowsImage from "../../Data/Assets/Images/PlataformaXL/Workflows.png";




import { BsStars } from "react-icons/bs";
import { PiBookOpen, PiChartLineUp, PiFileText, PiPaintBrush } from "react-icons/pi";

export const PlatformTabs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tabs = [
    { label: "Workflows", image: WorkflowsImage },
    { label: "Base conocimiento", image: BaseConocimientoImage },
    { label: "Estadísticas", image: EstadisticasImage },
    { label: "Informes", image: InformesImage },
    { label: "Apariencia", image: AparienciaImage },
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const tabRefs = useRef([]);
  const underlineRef = useRef(null);

  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleTabClick = (index) => {
    if (index !== selectedTab) {
      setIsFadingOut(true);
      setTimeout(() => {
        setSelectedTab(index);
        setIsFadingOut(false);
      }, 300);
    }
  };

  useEffect(() => {
    const targetIndex = hoveredIndex !== null ? hoveredIndex : selectedTab;
    if (underlineRef.current && tabRefs.current[targetIndex]) {
      const tab = tabRefs.current[targetIndex];
      const tabRect = tab.getBoundingClientRect();
      const containerRect = tab.parentElement.getBoundingClientRect();
      underlineRef.current.style.left = `${
        tabRect.left - containerRect.left
      }px`;
      underlineRef.current.style.width = `${tabRect.width}px`;
    }
  }, [hoveredIndex, selectedTab]);

  // ---------------- Para el carusel en móvil --------------------------

  const itemsData = [
    {
      id: 1,
      title: "Workflows",
      icon: <PiBookOpen />, // Representa flujos de trabajo
      imageUrl: WorkflowsImageXS,
    },
    {
      id: 2,
      title: "Base Conocimiento",
      icon: <PiBookOpen />, // Representa contenido educativo
      imageUrl: BaseConocimientoImageXS,
    },
    {
      id: 3,
      title: "Estadísticas",
      icon: <PiChartLineUp />, // Representa datos o análisis
      imageUrl: EstadisticasImageXS,
    },
    {
      id: 4,
      title: "Recomendaciones",
      icon: <PiFileText />, // Representa sugerencias o ideas
      imageUrl: RecomendacionesImageXS,
    },
    {
      id: 5,
      title: "Apariencia",
      icon: <PiPaintBrush />, // Representa personalización estética
      imageUrl: AparienciaImageXS,
    },
];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [transitionDirection, setTransitionDirection] = useState("none");

  // Cambio automático de ítem cada 3s
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  // Función para cambiar manualmente al ítem "index"
  const handleIndicatorClick = (index) => {
    setTransitionDirection(index > currentIndex ? "left" : "right");
    setCurrentIndex(index);
  };

  // Avanza al siguiente ítem
  const handleNext = () => {
    setTransitionDirection("left");
    setCurrentIndex((prevIndex) =>
      prevIndex === itemsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Ítem actual
  const currentItem = itemsData[currentIndex];

  // Estilos generales inline
  const styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      margin: "0 auto",
      paddingTop:"1rem",
      paddingBottom: "4rem",
      position: "relative",
    },
    cardContainer: {
      width: "100%",
      backgroundColor: "#fff",
      borderRadius: "1.25rem",
      maxWidth:"35rem",
      overflow: "hidden",
      boxShadow: "0 2px 6px rgba(255, 255, 255, 0.4)",
      position: "relative",
      
    },
    // El contenedor donde se muestra la imagen tendrá la animación de slide
    // según la dirección de la transición
    imageContainerBase: {
      width: "100%",
      height: "25rem",
      position: "relative",
    },
    cardImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "block",
    },
    // Título e ícono (fade in)
    titleContainerBase: {
      backgroundColor: colors.grey[900],
      display: "flex",
      alignItems: "center",
      padding: "1rem",
      gap: "0.5rem",
    },
    cardIcon: {
      fontSize: "1.25rem",
      color: colors.grey[100],
    },
    cardTitle: {
      fontSize: "1rem",
      margin: 0,
      color: colors.grey[100],
      fontFamily: "Inter, sans-serif",
    },
    // Indicadores
    indicatorsContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "0.5rem",
      marginTop: "1.5rem",
    },
    indicatorBase: {
      width: "60px",
      height: "5px",
      backgroundColor: colors.grey[600],
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    indicatorActive: {
      backgroundColor: colors.grey[100],
    },
  };

  // Para simular animaciones con keyframes, incluimos un bloque <style> interno.
  // Asignaremos clases en línea usando "className" y un pequeño condicional.
  // Así mantenemos todo en un solo archivo.
  //
  // NOTA: Aunque la petición es "todo inline", los keyframes no pueden declararse
  // directamente como un objeto JS. Se inyectan en un <style> y se utilizan
  // las clases condicionalmente.
  const slideClass =
    transitionDirection === "left"
      ? "slide-left"
      : transitionDirection === "right"
      ? "slide-right"
      : "";

  // Para el fade-in del título e icono
  const fadeClass = "fade-in";

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: isMobile ? "4rem":"6rem",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: `${pagewidth + 200}px`,
        }}
      >
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="center">
            {" "}
            <Stack direction="row">
              <Title
                title={"Descubre nuestra plataforma"}
                customColor={colors.grey[100]}
              />
              <Box sx={{ pl: "1rem", pb: "0.75rem" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width={isMobile ? "30px" : "40px"}
                  height={isMobile ? "30px" : "40px"}
                >
                  <defs>
                    <linearGradient
                      id="gradiente"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop
                        offset="0%"
                        style={{ stopColor: "#c43eda", stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: "#95f1fc", stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                  <path
                    d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"
                    fill="url(#gradiente)"
                  />
                </svg>
              </Box>
            </Stack>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box maxWidth="65rem" p="0rem 0rem 1rem 0rem">
              <PrimaryText
                customColor={colors.grey[300]}
                fontSize={"1rem"}
                center
                text={"Diseña para agencias, diseñada para ti."}
              />
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          {/* Contenedor para centrar tabs e imagen */}
          {isMobile ? (
            <div style={styles.wrapper}>
              {/* Inyectamos las keyframes y reglas de animación como un bloque de estilos */}
              <style>
                {`
            @keyframes slideLeft {
              0% {
                transform: translateX(100%);
              }
              100% {
                transform: translateX(0);
              }
            }
            @keyframes slideRight {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(0);
              }
            }
            .slide-left {
              animation: slideLeft 0.6s ease forwards;
            }
            .slide-right {
              animation: slideRight 0.6s ease forwards;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
            .fade-in {
              animation: fadeIn 0.5s ease;
            }
          `}
              </style>

              {/* Contenedor de la tarjeta */}
              <div style={styles.cardContainer}>
                {/* Imagen con la animación horizontal */}
                <div
                  style={styles.imageContainerBase}
                  className={slideClass}
                  key={currentItem.id}
                >
                  <img
                    src={currentItem.imageUrl}
                    alt={currentItem.title}
                    style={styles.cardImage}
                  />
                </div>

                {/* Sección del título con fade-in */}
                <div style={styles.titleContainerBase} className={fadeClass}>
                  <span style={styles.cardIcon}>{currentItem.icon}</span>
                  <h2 style={styles.cardTitle}>{currentItem.title}</h2>
                </div>
              </div>

              {/* Indicadores */}
              <div style={styles.indicatorsContainer}>
                {itemsData.map((item, index) => {
                  const isActive = index === currentIndex;
                  return (
                    <div
                      key={item.id}
                      onClick={() => handleIndicatorClick(index)}
                      style={{
                        ...styles.indicatorBase,
                        ...(isActive ? styles.indicatorActive : {}),
                      }}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <Box
              sx={{
                maxWidth: `${pagewidth}px`,
                margin: "0 auto",
                pb: "6rem", // padding inferior
                pt: "2rem",
                justifyContent: "center", // Centra horizontalmente el contenido
              }}
            >
              {/* Tabs */}
              <Box width="100%" display="flex" justifyContent="center">
                <Box
                  sx={{
                    position: "relative",
                    width: "55rem",
                    display: "flex",
                    justifyContent: "space-evenly",
                    backgroundColor: "transparent",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <Box
                      key={tab.label}
                      onClick={() => handleTabClick(index)}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      ref={(el) => (tabRefs.current[index] = el)}
                      sx={{
                        flex: 1,
                        textAlign: "center",
                        padding: "0.625rem",
                        cursor: "pointer",
                        fontSize: "1rem",
                        fontFamily: "Inter",
                        position: "relative",
                        transition: "color 0.3s ease-in-out",
                        color: "white",
                        fontWeight: selectedTab === index ? 600 : 400,
                      }}
                    >
                      {tab.label}
                    </Box>
                  ))}

                  <Box
                    ref={underlineRef}
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      height: "3px",
                      backgroundColor: "white",
                      transition: "all 0.3s ease",
                    }}
                  />
                </Box>
              </Box>

              {/* Imagen justo debajo de las tabs, sin espacio extra */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={tabs[selectedTab].image}
                  alt={tabs[selectedTab].label}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    borderRadius: "1rem", // borderRadius de 1rem
                    opacity: isFadingOut ? 0 : 1,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
