import { useTheme } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import { useScreenSize } from "../../hooks/ScreenSizeContext";
import { Title } from "./Title";
import { TopText } from "../../Components/TopText";
import { PrimaryText } from "../../Components/PrimaryText";

export const TitleBox2 = ({toptext, title, subtitle}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { pagewidth } = useScreenSize();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex", // Habilita flexbox
        flexDirection: "column", // Organiza los hijos en columna
        justifyContent: "center", // Centra los hijos verticalmente
        alignItems: "center",
        mt: "4rem",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: `${pagewidth + 200}px`,
        }}
      >
        <Grid item xs={12}>
          <TopText text={toptext} />
        </Grid>
        <Grid item xs={12}>
          <Title
            title={
              title
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            display: "flex", // Habilita flexbox
            flexDirection: "column", // Organiza los hijos en columna
            justifyContent: "center", // Centra los hijos verticalmente
            alignItems: "center",
          }}
        >
          <Box maxWidth="60rem" p="1rem 0rem">
            <PrimaryText
              fontSize={"1rem"}
              center
              text={subtitle}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
